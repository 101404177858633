import DeviceInfo from "react-native-device-info"

export const isMobile = () => {

    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
        return true;
    } else {
        return false;
    }
}

export const isWeb = () => {
    const deviceType = DeviceInfo.getDeviceType();

    console.log(deviceType);
    return deviceType === "web";
}

export const getDeviceType = () => {
    const deviceType = DeviceInfo.getDeviceType();
    return deviceType;
}