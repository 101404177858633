import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function CarIconNormal(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={29}
            height={29}
            viewBox="0 0 29 29"
            fill="none"
            {...props}
        >
            <G opacity={0.5} stroke="#000" strokeWidth={0.860094}>
                <Path
                    d="M12.383 13.422h4.129m-4.645 2.064h.516m4.129 0h.516"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <Path d="M9.805 17.55v-3.402c0-.14.028-.277.083-.406l1.197-2.792a1.032 1.032 0 01.948-.626h4.832a1.032 1.032 0 01.948.626l1.197 2.792c.055.128.084.267.083.407v3.4m-9.288 0v1.239a.31.31 0 00.31.31h1.444a.31.31 0 00.31-.31v-1.239m-2.064 0h2.064m7.224 0v1.239a.31.31 0 01-.31.31H17.34a.309.309 0 01-.31-.31v-1.239m2.064 0H17.03m-5.16 0h5.16" />
            </G>
        </Svg>
    )
}

export default CarIconNormal;
