import * as React from "react"
import Svg, { Path } from "react-native-svg"

function QuoteIconActive(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={29}
            viewBox="0 0 30 29"
            fill="none"
            {...props}
        >
            <Path
                d="M15.399 24.083a9.633 9.633 0 100-19.267 9.633 9.633 0 000 19.267z"
                fill="#0B3C49"
            />
            <Path
                d="M17.964 12.65v-.261c0-1.177-1.735-2.065-4.036-2.065-2.302 0-4.037.888-4.037 2.065v1.876c0 .98 1.204 1.759 2.935 1.992v.26c0 1.177 1.736 2.064 4.037 2.064 2.302 0 4.037-.887 4.037-2.064V14.64c0-.97-1.166-1.75-2.936-1.99zm-5.871 2.688c-.9-.256-1.468-.68-1.468-1.073v-.66c.374.271.875.49 1.467.637v1.096zm3.67-1.096c.592-.147 1.093-.366 1.467-.637v.66c0 .394-.569.816-1.468 1.073v-1.096zm-.735 3.348c-.898-.257-1.468-.68-1.468-1.073v-.196a8.653 8.653 0 00.89-.008c.19.07.383.128.578.176v1.101zm0-2.093a7.325 7.325 0 01-2.202 0V14.38a8.24 8.24 0 001.101.073 8.24 8.24 0 001.101-.072v1.116zm2.936 2.252a7.42 7.42 0 01-2.202 0v-1.12a8.241 8.241 0 002.202.003v1.117zm2.202-1.232c0 .394-.57.816-1.468 1.073v-1.096c.593-.147 1.094-.366 1.468-.637v.66z"
                fill="#fff"
            />
        </Svg>
    )
}

export default QuoteIconActive
