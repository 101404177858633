import './App.css';
import AutoHomePage from './screens/autoHome';
import TermsPage from './screens/terms';
import PrivacyPage from './screens/privacy';
import AutoInsuranceTabs from './screens/autoInsurance';
import HealthTabs from './screens/health';
import { HashRouter, Route, Routes } from "react-router-dom"

function App() {

  return (
    <HashRouter>
    <Routes>
      <Route path="/" element={<AutoHomePage />} />
      <Route path="*" element={<AutoHomePage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/auto" element={<AutoInsuranceTabs />} />
      <Route path="/health" element={<HealthTabs />} />
    </Routes>
    </HashRouter>
  );
}
export default App;
