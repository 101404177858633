import * as React from "react"
import Svg, { Path } from "react-native-svg"

function CarIconActive(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={29}
            height={29}
            viewBox="0 0 29 29"
            fill="none"
            {...props}
        >
            <Path
                d="M14.454 24.083a9.633 9.633 0 100-19.267 9.633 9.633 0 000 19.267z"
                fill="#0B3C49"
            />
            <Path
                d="M12.712 13.191h3.823m-4.3 1.912h.477m3.823 0h.478"
                stroke="#fff"
                strokeWidth={1.25}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <Path
                d="M10.32 17.014v-3.149c0-.13.027-.257.078-.376l1.108-2.585a.956.956 0 01.878-.58h4.474a.955.955 0 01.878.58l1.109 2.585c.05.119.077.247.077.377v3.148m-8.602 0v1.147a.287.287 0 00.287.287h1.338a.287.287 0 00.287-.287v-1.147m-1.912 0h1.912m6.69 0v1.147a.287.287 0 01-.287.287h-1.338a.287.287 0 01-.287-.287v-1.147m1.912 0H17.01m-4.778 0h4.778"
                stroke="#fff"
                strokeWidth={1.25}
            />
        </Svg>
    )
}

export default CarIconActive;
