import { View, Pressable } from 'react-native';
import StartIcon from '../icons/startIcon';
import TabItem from './TabItem';
import CarIconNormal from '../icons/carIconNormal';
import DriverIconNormal from '../icons/driverIconNormal';
import QuoteIconNormal from '../icons/quoteIconNormal';
import CarIconActive from '../icons/carIconActive';
import DriverIconActive from '../icons/driverIconActive';
import QuoteIconActive from '../icons/quoteIconActive';
import CompleteIcon from '../icons/completeIcon';
import { QuoteInfoType } from '../constants/quoteInfoType';
import AboutIconActive from "../icons/aboutIconActive";
import AboutIconNormal from "../icons/aboutIconNormal";

// const AutoTabData = [
//     {
//         title: "Start",
//         type: QuoteInfoType.Start,
//         isComplete: false,
//     },
//     {
//         title: "Cars",
//         type: QuoteInfoType.Car,
//         isComplete: true,
//     },
//     {
//         title: "Drivers",
//         type: QuoteInfoType.Driver,
//         isComplete: true,
//     },
//     {
//         title: "Quote",
//         type: QuoteInfoType.Quote,
//         isComplete: false,
//     }
// ]

const AutoTab = (props) => {
    const {tabs} = props;

    const getIcon = (type, isActive) => {
        let icon = null;
        switch (type) {
            case QuoteInfoType.Start:
                icon = <StartIcon />
                break;
            case QuoteInfoType.About:
                icon = isActive ? <AboutIconActive /> : <AboutIconNormal />
                break;
            case QuoteInfoType.Car:
                icon = isActive ? <CarIconActive /> : <CarIconNormal />;
                break;
            case QuoteInfoType.Driver:
                icon = isActive ? <DriverIconActive /> : <DriverIconNormal />
                break;
            case QuoteInfoType.Quote:
                icon = isActive ? <QuoteIconActive /> : <QuoteIconNormal />
                break;
            default:
                break;
        }

        return icon;
    }


    // const getIsComplete = (type) => {

    //     let isComplete = false;
    //     switch (type) {
    //         case QuoteInfoType.Start:
    //             isComplete = props.currentState === ;
    //             break;
    //         case QuoteInfoType.Car:
    //             isComplete = props.currentState >= props.numberOfStartState && props.currentState < props.numberOfCarState;
    //             break;
    //         case QuoteInfoType.Driver:
    //             isComplete = props.currentState >= props.numberOfCarState && props.currentState < props.numberOfDriverState;
    //             break;
    //         case QuoteInfoType.Quote:
    //             isComplete = props.currentState >= props.numberOfDriverState && props.currentState < props.numberOfQuoteState;
    //             break;
    //     }

    //     return isComplete;
    // }

    return (<View style={{ width: "100%", height: window.innerHeight * 0.1, justifyContent: "center", alignItems: "center", flexDirection: "row", position: "relative" }}>
        {tabs?.map((item, index) => {
            const isActive = props.sectionIndex === index;
            const isComplete = index < props.sectionIndex;
            return <Pressable style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
                onPress={() => { props.onTabSelected(item.type) }}>
                <TabItem title={item.title} isActive={isActive} isComplete={isComplete}>
                    {isComplete ? <CompleteIcon /> : getIcon(item.type, isActive)}
                </TabItem>
            </Pressable>
        })}
    </View>);
}

export default AutoTab;
