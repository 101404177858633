import AutoTab from '../components/AutoTab';
import {Image, Pressable, StyleSheet, Text, TextInput, View} from "react-native";
import React, {useEffect, useState} from 'react';
import Footer from '../components/Footer';
import {isMobile} from '../helpers/deviceUtils';
import validator from 'validator';
import {ERROR_MESSAGES} from '../constants/errorMessages';
import {INPUT_TYPE} from '../constants/inputType';
import {QuoteInfoType} from '../constants/quoteInfoType';
import US_STATES from '../constants/us_states.json';
import Select from 'react-select'

const isMobileDevice = isMobile();

const tabs = [
    {
        title: "Start",
        type: QuoteInfoType.Start,
        isComplete: false,
    },
    {
        title: "About you",
        type: QuoteInfoType.About,
        isComplete: true,
    },
    {
        title: "Quote Information",
        type: QuoteInfoType.Quote,
        isComplete: false,
    }
];

const HealthInsuranceInfo = {
    [QuoteInfoType.Start]: [{
        title: "Hi! Let's start with a few questions",
        description: "Do you have health insurance currently?",
        selectedOption: "",
        isComplete: false,
        options: [
            "Yes",
            "No"
        ],
        containerStyles: {
            flexDirection: 'row',
        },
        itemStyles: {
            maxWidth: 135,
        }
    },
        {
            title: "Your age bracket",
            description: "Please enter your age",
            placeholder: "Enter your age",
            errorMessage: "",
            inputMode: "numeric",
            type: INPUT_TYPE.AGE,
            isComplete: false,
            content: "",
        }],
    [QuoteInfoType.About]: [{
        title: "Select your state",
        errorMessage: "",
        inputMode: "numeric",
        type: 'select',
        isComplete: false,
        content: "",
        selectedOption: "",
    }],
    [QuoteInfoType.Quote]: [{
        title: "What are you looking for in health cover?",
        selectedOption: "",
        isComplete: false,
        options: [
            "Better health cover",
            "I have a health concern I need cover",
            "I want cheaper coverage",
            "I want to compare my options",
            "I’m planning on having a baby "
        ],
    },
        {
            isComplete: false,
            contents: [
                {
                    title: "Email address",
                    placeholder: "Enter your email address",
                    inputMode: "email",
                    type: INPUT_TYPE.EMAIL,
                    errorMessage: "",
                    content: "",
                },
                {
                    title: "Phone number",
                    placeholder: "(___) ___ ____",
                    errorMessage: "",
                    inputMode: "tel",
                    type: INPUT_TYPE.PHONE,
                    content: "",
                },
            ],
            nextButtonLabel: 'GET QUOTE',
        }],
}

const HealthInsuranceKeys = Object.keys(HealthInsuranceInfo);

function HealthTabs() {
    const [sectionIndex, setSectionIndex] = useState(0);
    const [blockIndex, setBlockIndex] = useState(0);
    // const { zipCode } = route.params;
    const [autoData, setAutoData] = useState(Object.assign({}, HealthInsuranceInfo));

    useEffect(() => {
        const generateQuoteInfo = (item) => {
            if (item.contents) {
                let quoteInfos = "";
                for (let index = 0; index < item.contents.length; index++) {
                    const quoteInfo = generateQuoteInfo(item.contents[index]);
                    quoteInfos += quoteInfo;
                }

                return quoteInfos;
            }

            const question = item.description || item.title || item.placeholder;
            const answer = item.selectedOption || item.content;

            return `${question}${question.trim().endsWith("?") ? "" : ":"} ${answer}\n`;
        }

        const sendQuote = async () => {
            if (sectionIndex >= HealthInsuranceKeys.length) {
                let data = ""
                for (let index = 0; index < HealthInsuranceKeys.length; index++) {
                    const quoteSection = HealthInsuranceInfo[HealthInsuranceKeys[index]];
                    for (let quoteSectionIndex = 0; quoteSectionIndex < quoteSection.length; quoteSectionIndex++) {
                        data += generateQuoteInfo(quoteSection[quoteSectionIndex]);
                    }
                }

                console.log(data);
            }
        }
        sendQuote();
    }, [sectionIndex]);


    const onPress = async () => {
        if (sectionIndex < HealthInsuranceKeys.length) {
            if (HealthInsuranceInfo[HealthInsuranceKeys[sectionIndex]].length > blockIndex + 1) {
                setBlockIndex(blockIndex + 1);
            } else {
                setBlockIndex(0);
                setSectionIndex(sectionIndex + 1);
            }
        }
    }

    const renderTitle = (title) => {
        return (
            <Text style={styles.title}>{title}</Text>
        )
    }

    const renderDescription = (description) => {
        return (
            <Text style={styles.description}>{description}</Text>
        )
    }

    const renderError = (errorMessage) => {
        return (
            <Text style={[styles.description, {color: "red"}]}>{errorMessage}</Text>
        )
    }

    const renderNextButton = (isActive, item) => {
        return (
            <Pressable style={[styles.button, isActive && styles.buttonActive]}
                       disabled={!isActive}
                       onPress={() => {
                           if (item) {
                               const isValid = isDataValid(item);
                               if (!isValid) {
                                   setAutoData(Object.assign({}, HealthInsuranceInfo));
                                   return;
                               }
                           }

                           onPress();
                       }}>
                <Text
                    style={styles.buttonContent}>{item?.nextButtonLabel && item?.nextButtonLabel !== "" ? item.nextButtonLabel : "NEXT"}</Text>
            </Pressable>
        )
    }

    const isDataValid = (item) => {
        let isValid = true;
        if (item.contents) {
            for (let i = 0; i < item.contents.length; i++) {
                isValid = isDataValid(item.contents[i]);
                if (!isValid) {
                    return false;
                }
            }
        }

        if (item.content) {
            item.errorMessage = "";
            if (item.type === INPUT_TYPE.EMAIL) {
                isValid = validator.isEmail(item.content);
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.EMAIL;

                    return false;
                }
            }

            if (item.type === INPUT_TYPE.PHONE) {
                isValid = validator.isMobilePhone(item.content);
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.PHONE;

                    return false;
                }
            }

            if (item.type === INPUT_TYPE.ZIP_CODE) {
                isValid = validator.isPostalCode(item.content);
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.ZIP_CODE;
                    return false;
                }
            }

            if (item.type === INPUT_TYPE.AGE) {
                isValid = validator.isNumeric(item.content) && item.content.length < 4;
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.AGE;
                    return false;
                }
            }

            if (item.type === INPUT_TYPE.YEAR) {
                isValid = validator.isNumeric(item.content) && item.content.length === 4;
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.YEAR;
                    return false;
                }
            }

            if (item.type === INPUT_TYPE.NUMBER) {
                isValid = validator.isNumeric(item.content);
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.GENERIC;
                    return false;
                }
            }

            // if (item.type === INPUT_TYPE.DATE) {
            //     isValid = validator.isDate(item.content, {
            //         format: "YYYY/MM/DD",
            //     });
            //     if (!isValid) {
            //         item.errorMessage = ERROR_MESSAGES.DATE;
            //         return false;
            //     }
            // }
        }

        return true;
    }

    const renderOptionButton = (isActive, content, onSelect, item) => {
        return (
            <Pressable style={[styles.optionButton, isActive && styles.optionButtonActive, item.itemStyles]}
                       onPress={() => onSelect(content)}>
                <Text style={styles.optionButtonContent}>{content}</Text>
            </Pressable>
        )
    }

    const renderSelectInput = (item) => {
        const onSelect = (value) => {
            item.selectedOption = value.label;
            setAutoData(Object.assign({}, HealthInsuranceInfo))
        };

        return (
            <View style={styles.textInputView}>
                <Select
                    menuPortalTarget={document.querySelector('body')}
                    styles={{
                        container: () => ({flex: 1}),
                        control: (base, props) =>
                            ({
                                ...base,
                                backgroundColor: '#F0FAF5',
                                borderWidth: 2,
                                boxShadow: props.isFocused ? 0 : 0,
                                borderColor: props.isFocused
                                    ? '#70E189'
                                    : base.borderColor,
                                '&:hover': {
                                    borderColor: props.isFocused
                                        ? '#70E189'
                                        : base.borderColor,
                                }
                            }),
                    }}
                    isSearchable={true}
                    options={US_STATES}
                    onChange={(newValue) => onSelect(newValue)}/>
            </View>
        )
    }

    const renderTextInput = (data) => {
        return (
            <View style={styles.textInputView}>
                <TextInput placeholder={data.placeholder}
                           style={styles.textInput}
                           onChangeText={(newText) => {
                               data.content = newText;
                               setAutoData(Object.assign({}, HealthInsuranceInfo));
                           }}
                           inputMode={data.inputMode}
                           value={data.content}></TextInput>
            </View>
        )
    }

    const renderOptionButtons = (item) => {
        const onSelect = (content) => {
            item.selectedOption = content;
            setAutoData(Object.assign({}, HealthInsuranceInfo))
        };
        return (
            <View style={[styles.columnDirectionView, item.containerStyles]}>
                {item.options.map((data) => renderOptionButton(data === item.selectedOption, data, onSelect, item))}
            </View>
        )
    };

    const renderViewWithTextInput = (item) => {
        return (
            <View style={styles.columnDirectionView}>
                {item.title && renderTitle(item.title)}
                {item.description && renderDescription(item.description)}
                {item.errorMessage && renderError(item.errorMessage)}
                {renderTextInput(item)}
                {item.isComplete != null && renderNextButton(item.content !== "", item)}
            </View>
        )
    }

    const renderViewWithButtonOptions = (item) => {
        return (
            <View style={[styles.columnDirectionView, {alignItems: "flex-start"}]}>
                {renderTitle(item.title)}
                {item.description && renderDescription(item.description)}
                {renderOptionButtons(item)}
                {renderNextButton(item.selectedOption !== "")}
            </View>
        )
    }

    const renderViewWithMultipleTextInput = (item) => {
        const firstEmptyField = item.contents.findIndex((data) => data.content === "");
        return (
            <View style={styles.columnDirectionView}>
                {renderTitle(item.title)}
                {item.description && renderDescription(item.description)}
                {item.contents.map((data) => renderViewWithTextInput(data))}
                {renderNextButton(firstEmptyField === -1, item)}
            </View>
        )
    }

    const renderViewWithSelectionInput = (item) => {
        return (
            <View style={styles.columnDirectionView}>
                {item.title && renderTitle(item.title)}
                {item.description && renderDescription(item.description)}
                {item.errorMessage && renderError(item.errorMessage)}
                {renderSelectInput(item)}
                {renderNextButton(item.selectedOption !== "")}
            </View>
        );
    }

    const renderCompleteView = () => {
        return (
            <View style={styles.columnDirectionView}>
                {renderTitle(messages.complete)}
                <Image source={require('../assets/complete.png')}
                       style={styles.completeImage}
                       resizeMode='contain'/>
                {renderTitle("Thanks!")}
                <Text style={styles.completeDescription}>{messages.completeDescription}</Text>
            </View>
        )
    }

    const renderQuoteDetailView = (item) => {
        if (item.type === 'select') {
            return renderViewWithSelectionInput(item);
        } else if (item.options != null) {
            return renderViewWithButtonOptions(item);
        } else if (item.contents != null) {
            return renderViewWithMultipleTextInput(item);
        } else if (item.content != null) {
            return renderViewWithTextInput(item);
        }
    }
    return (
        <>
            <AutoTab tabs={tabs} sectionIndex={sectionIndex}
                     blockIndex={blockIndex}
                     onTabSelected={(type) => {
                         const newSectionIndex = HealthInsuranceKeys.findIndex((key) => key === type);

                         if (newSectionIndex > -1) {
                             setSectionIndex(newSectionIndex);
                             setBlockIndex(0);
                         }
                     }}/>
            <View style={styles.detailView}>
                <View style={[styles.detailContent, !isMobileDevice && {width: "40%", minWidth: 400}]}>
                    {sectionIndex >= HealthInsuranceKeys.length ? renderCompleteView()
                        : renderQuoteDetailView(HealthInsuranceInfo[HealthInsuranceKeys[sectionIndex]][blockIndex])}
                </View>
            </View>

            <Footer textStyle={{color: "black"}}
                    style={[{
                        position: "absolute",
                        marginTop: window.innerHeight * 0.94,
                        flexDirection: "row",
                        justifyContent: "center",
                        width: "100%",
                        alignSelf: "flex-end"
                    }]}>
            </Footer>
        </>
    );
}

const styles = StyleSheet.create({
    title: {
        color: "#111111",
        fontSize: 20,
        lineHeight: "100%",
        fontWeight: "600",
        marginVertical: 10
    },
    description: {
        color: "#111111",
        fontSize: 14,
        lineHeight: "100%",
        fontWeight: "600",
        marginVertical: 10,
        opacity: 0.75
    },
    button: {
        borderRadius: 60,
        backgroundColor: "rgba(112, 225, 137, 0.45)",
        marginHorizontal: 5,
        alignItems: "center",
        justifyContent: "center",
        marginTop: 20,
        marginBottom: 15,
        height: 40,
        paddingVertical: 16,
        paddingHorizontal: 55,
        alignSelf: 'flex-end',
    },
    buttonActive: {
        backgroundColor: "#70E189"
    },
    buttonContent: {
        color: "#191C1F",
        fontSize: 14,
        lineHeight: "100%",
        fontWeight: "700",
    },
    optionButton: {
        marginVertical: 5,
        height: 40,
        borderRadius: 7,
        borderColor: "rgba(11, 60, 73, 0.35)",
        borderWidth: 1,
        backgroundColor: "#F0FAF5",
        flex: 1,
        marginHorizontal: 5,
        alignItems: "center",
        justifyContent: "center",
        width: '100%',
    },
    optionButtonActive: {
        borderWidth: 2,
        borderColor: "#70E189",
        backgroundColor: "#B8F0C4"
    },
    optionButtonContent: {
        color: "#000000",
        fontSize: 14,
        lineHeight: "100%",
        fontWeight: "600",
        marginVertical: 15,
        opacity: 0.75
    },
    textInputView: {
        marginVertical: 5,
        width: "100%",
        height: 40,
        flexDirection: "row"
    },
    textInput: {
        borderRadius: 7,
        borderColor: "rgba(11, 60, 73, 0.35)",
        borderWidth: 1,
        width: "100%",
        paddingHorizontal: 15
    },
    columnDirectionView: {
        flexDirection: "column",
        width: "100%",
        alignItems: "center"
    },
    completeImage: {
        width: "80%",
        height: 200
    },
    completeDescription: {
        color: "#111111",
        fontSize: 14,
        lineHeight: "100%",
        fontWeight: "600",
        marginVertical: 10,
        opacity: 0.75,
        textAlign: 'center'
    },
    detailView: {
        width: "100%",
        height: window.innerHeight * 0.9,
        backgroundColor: "#EAF8F0",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "relative"
    },
    detailContent: {
        width: "90%",
        backgroundColor: "white",
        marginTop: 20, borderRadius: 10,
        borderColor: "rgba(11, 60, 73, 0.50)",
        borderWidth: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: 15
    }
})

const messages = {
    complete: "Complete!",
    completeDescription: "One of our insurance partners will be in contact with you shortly with more information."
}

export default HealthTabs;
