import AutoTab from '../components/AutoTab';
import { View, Text, Pressable, TextInput, StyleSheet, Image } from "react-native";
import { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import { isMobile } from '../helpers/deviceUtils';
import validator from 'validator';
import { ERROR_MESSAGES } from '../constants/errorMessages';
import { INPUT_TYPE } from '../constants/inputType';
import { QuoteInfoType } from '../constants/quoteInfoType';
import { useSearchParams } from 'react-router-dom';

const isMobileDevice = isMobile();

const AutoTabData = [
    {
        title: "Start",
        type: QuoteInfoType.Start,
        isComplete: false,
    },
    {
        title: "Cars",
        type: QuoteInfoType.Car,
        isComplete: true,
    },
    {
        title: "Drivers",
        type: QuoteInfoType.Driver,
        isComplete: true,
    },
    {
        title: "Quote",
        type: QuoteInfoType.Quote,
        isComplete: false,
    }
]

const CarInsuranceInfo = {
    [QuoteInfoType.Start]: [{
        title: "Hi! Let's start with a few questions",
        description: "Do you have auto insurance currently?",
        selectedOption: "",
        isComplete: false,
        options: [
            "Yes",
            "No"
        ]
    },
    {
        title: "Your age bracket",
        description: "Please enter your age",
        placeholder: "Enter your age",
        errorMessage: "",
        inputMode: "numeric",
        type: INPUT_TYPE.AGE,
        isComplete: false,
        content: "",
    }],
    [QuoteInfoType.Car]: [{
        title: "Tell us the year of your car",
        description: "Select the year",
        placeholder: "Enter car year",
        errorMessage: "",
        inputMode: "numeric",
        type: INPUT_TYPE.YEAR,
        isComplete: false,
        content: "",
    },
    {
        title: "Tell us the make",
        placeholder: "Enter the car make",
        inputMode: "text",
        isComplete: false,
        content: "",
    },
    {
        title: "Tell us the model",
        placeholder: "Enter the car model",
        inputMode: "text",
        isComplete: false,
        content: "",
    },
    {
        title: "What is the main use of your car?",
        selectedOption: "",
        isComplete: false,
        options: [
            "Commuting or personal use",
            "Commute to school",
            "Pleasure",
            "Business",
            "Rideshare"
        ]
    },
    {
        title: "How many miles do you drive per day?",
        selectedOption: "",
        isComplete: false,
        options: [
            "10 Miles",
            "20 Miles",
            "40 Miles",
            "60 Miles+",
        ]
    },
    {
        title: "Do you own your car?",
        selectedOption: "",
        isComplete: false,
        options: [
            "Yes - paid off",
            "No - still paying off",
            "Leased",
        ]
    },
    {
        title: "Do you want full coverage?",
        selectedOption: "",
        isComplete: false,
        options: [
            "Yes",
            "No",
        ]
    }],
    [QuoteInfoType.Driver]: [{
        title: "Do you have an active non suspended license?",
        selectedOption: "",
        isComplete: false,
        options: [
            "Yes",
            "No"
        ]
    },
    {
        title: "Now let's get to know you a little better",
        description: "Insurance companies need your name & date of birth to give accurate prices. Some insurance companies may not return a price if you do not provide this information.",
        isComplete: false,
        contents: [{
            placeholder: "First name",
            inputMode: "text",
            content: "",
        },
        {
            placeholder: "Last name",
            inputMode: "text",
            content: "",
        },
        {
            placeholder: "DOB (YYYY/MM/DD)",
            errorMessage: "",
            inputMode: "text",
            type: INPUT_TYPE.DATE,
            content: "",
        }],
    },
    {
        isComplete: false,
        contents: [
            {
                title: "What year did u get your first drivers license?",
                placeholder: "Enter the year you got your drivers license",
                errorMessage: "",
                inputMode: "numeric",
                type: INPUT_TYPE.YEAR,
                content: "",
            },
            {
                title: "How many years experience do you have driving?",
                placeholder: "Enter the years experience you have driving",
                inputMode: "numeric",
                type: INPUT_TYPE.NUMBER,
                content: "",
            },
        ],
    },
    {
        title: "If you have one who is your current insurance provider?",
        placeholder: "Enter your current insurance provider",
        inputMode: "text",
        isComplete: false,
        content: "",
    },
    {
        title: "Describe any accidents you have had in the last 5 years",
        placeholder: "Enter any accidents you have had in the last 5 years",
        inputMode: "text",
        isComplete: false,
        content: "",
    }],
    [QuoteInfoType.Quote]: [{
        title: "Where do you park your car overnight?",
        placeholder: "Location",
        inputMode: "text",
        isComplete: false,
        content: "",
    },
    {
        isComplete: false,
        contents: [
            {
                title: "Email address",
                placeholder: "Enter your email address",
                inputMode: "email",
                type: INPUT_TYPE.EMAIL,
                errorMessage: "",
                content: "",
            },
            {
                title: "Phone number",
                placeholder: "(___) ___ ____",
                errorMessage: "",
                inputMode: "tel",
                type: INPUT_TYPE.PHONE,
                content: "",
            },
        ],
    }],
}

const CarInsuranceKeys = Object.keys(CarInsuranceInfo);

function AutoInsuranceTabs() {
    const [sectionIndex, setSectionIndex] = useState(0);
    const [blockIndex, setBlockIndex] = useState(0);
    const [searchParams] = useSearchParams();
    const zipCode = searchParams.get('zipCode')
    console.log(zipCode);
    const [autoData, setAutoData] = useState(Object.assign({}, CarInsuranceInfo));

    useEffect(() => {
        sendQuote();
    }, [sectionIndex]);

    const sendQuote = async () => {
        if (sectionIndex >= CarInsuranceKeys.length) {
            let data = ""
            for (let index = 0; index < CarInsuranceKeys.length; index++) {
                const quoteSection = CarInsuranceInfo[CarInsuranceKeys[index]];
                for (let quoteSectionIndex = 0; quoteSectionIndex < quoteSection.length; quoteSectionIndex++) {
                    data += generateQuoteInfo(quoteSection[quoteSectionIndex]);
                }
            }

            console.log(data);

            // try {

            //     const response = await axios.post("https://api.emailjs.com/api/v1.0/email/send", {
            //         service_id: "service_v6jln6h",
            //         template_id: "template_oxq3iwk",
            //         user_id: "23lB37zM51IUM9_c3",
            //         template_params: {
            //             message: data
            //         }
            //     })

            //     console.log(response)
            // } catch (error) {
            //     console.log(error);
            // }
        }
    }

    const generateQuoteInfo = (item) => {
        if (item.contents) {
            let quoteInfos = "";
            for (let index = 0; index < item.contents.length; index++) {
                const quoteInfo = generateQuoteInfo(item.contents[index]);
                quoteInfos += quoteInfo;
            }

            return quoteInfos;
        }

        const question = item.description || item.title || item.placeholder;
        const answer = item.selectedOption || item.content;

        return `${question}${question.trim().endsWith("?") ? "" : ":"} ${answer}\n`;
    }
    const onPress = async () => {
        if (sectionIndex < CarInsuranceKeys.length) {
            if (CarInsuranceInfo[CarInsuranceKeys[sectionIndex]].length > blockIndex + 1) {
                setBlockIndex(blockIndex + 1);
            } else {
                setBlockIndex(0);
                setSectionIndex(sectionIndex + 1);
            }
        }
    }

    const renderTitle = (title) => {
        return (
            <Text style={styles.title}>{title}</Text>
        )
    }

    const renderDescription = (description) => {
        return (
            <Text style={styles.description}>{description}</Text>
        )
    }

    const renderError = (errorMessage) => {
        return (
            <Text style={[styles.description, { color: "red" }]}>{errorMessage}</Text>
        )
    }

    const renderNextButton = (isActive, item) => {
        return (
            <Pressable style={[styles.button, isActive && styles.buttonActive]}
                disabled={!isActive}
                onPress={() => {
                    if (item) {
                        const isValid = isDataValid(item);
                        if (!isValid) {
                            setAutoData(Object.assign({}, CarInsuranceInfo));
                            return;
                        }
                    }

                    onPress();
                }}>
                <Text style={styles.buttonContent}>{"NEXT"}</Text>
            </Pressable>
        )
    }

    const isDataValid = (item) => {
        let isValid = true;
        if (item.contents) {
            for (let i = 0; i < item.contents.length; i++) {
                isValid = isDataValid(item.contents[i]);
                if (!isValid) {
                    return false;
                }
            }
        }

        if (item.content) {
            item.errorMessage = "";
            if (item.type === INPUT_TYPE.EMAIL) {
                isValid = validator.isEmail(item.content);
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.EMAIL;

                    return false;
                }
            }

            if (item.type === INPUT_TYPE.PHONE) {
                isValid = validator.isMobilePhone(item.content);
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.PHONE;

                    return false;
                }
            }

            if (item.type === INPUT_TYPE.ZIP_CODE) {
                isValid = validator.isPostalCode(item.content);
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.ZIP_CODE;
                    return false;
                }
            }

            if (item.type === INPUT_TYPE.AGE) {
                isValid = validator.isNumeric(item.content) && item.content.length < 4;
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.AGE;
                    return false;
                }
            }

            if (item.type === INPUT_TYPE.YEAR) {
                isValid = validator.isNumeric(item.content) && item.content.length === 4;
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.YEAR;
                    return false;
                }
            }

            if (item.type === INPUT_TYPE.NUMBER) {
                isValid = validator.isNumeric(item.content);
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.GENERIC;
                    return false;
                }
            }

            // if (item.type === INPUT_TYPE.DATE) {
            //     isValid = validator.isDate(item.content, {
            //         format: "YYYY/MM/DD",
            //     });
            //     if (!isValid) {
            //         item.errorMessage = ERROR_MESSAGES.DATE;
            //         return false;
            //     }
            // }
        }

        return true;
    }

    const renderOptionButton = (isActive, content, onSelect) => {
        return (
            <Pressable style={[styles.optionButton, isActive && styles.optionButtonActive]}
                onPress={() => onSelect(content)}>
                <Text style={styles.optionButtonContent}>{content}</Text>
            </Pressable>
        )
    }

    const renderTextInput = (data) => {
        return (
            <View style={styles.textInputView}>
                <TextInput placeholder={data.placeholder}
                    style={styles.textInput}
                    onChangeText={(newText) => {
                        data.content = newText;
                        setAutoData(Object.assign({}, CarInsuranceInfo));
                    }}
                    inputMode={data.inputMode}
                    value={data.content}></TextInput>
            </View>
        )
    }

    const renderOptionButtons = (item) => {
        const onSelect = (content) => {
            item.selectedOption = content;
            setAutoData(Object.assign({}, CarInsuranceInfo))
        };
        return (
            <View style={styles.columnDirectionView}>
                {item.options.map((data) => renderOptionButton(data === item.selectedOption, data, onSelect))}
            </View>
        )
    };

    const renderViewWithTextInput = (item) => {
        return (
            <View style={styles.columnDirectionView}>
                {item.title && renderTitle(item.title)}
                {item.description && renderDescription(item.description)}
                {item.errorMessage && renderError(item.errorMessage)}
                {renderTextInput(item)}
                {item.isComplete != null && renderNextButton(item.content !== "", item)}
            </View>
        )
    }

    const renderViewWithButtonOptions = (item) => {
        return (
            <View style={[styles.columnDirectionView, { alignItems: "flex-start" }]}>
                {renderTitle(item.title)}
                {item.description && renderDescription(item.description)}
                {renderOptionButtons(item)}
                {renderNextButton(item.selectedOption !== "")}
            </View>
        )
    }

    const renderViewWithMultipleTextInput = (item) => {
        const firstEmptyField = item.contents.findIndex((data) => data.content === "");
        return (
            <View style={styles.columnDirectionView}>
                {renderTitle(item.title)}
                {item.description && renderDescription(item.description)}
                {item.contents.map((data) => renderViewWithTextInput(data))}
                {renderNextButton(firstEmptyField === -1, item)}
            </View>
        )
    }

    const renderCompleteView = () => {
        return (
            <View style={styles.columnDirectionView}>
                {renderTitle(messages.complete)}
                <Image source={require('../assets/complete.png')}
                    style={styles.completeImage}
                    resizeMode='contain' />
                {renderTitle("Thanks " + CarInsuranceInfo[QuoteInfoType.Driver][1].contents[0].content + "!")}
                {renderDescription(messages.completeDescription)}
            </View>
        )
    }

    const renderQuoteDetailView = (item) => {
        if (item.options != null) {
            return renderViewWithButtonOptions(item);
        } else if (item.contents != null) {
            return renderViewWithMultipleTextInput(item);
        } else if (item.content != null) {
            return renderViewWithTextInput(item);
        }
    }
    return (
        <>
            <AutoTab tabs={AutoTabData} sectionIndex={sectionIndex}
                blockIndex={blockIndex}
                onTabSelected={(type) => {
                    const newSectionIndex = CarInsuranceKeys.findIndex((key) => key === type);

                    if (newSectionIndex > -1) {
                        setSectionIndex(newSectionIndex);
                        setBlockIndex(0);
                    }
                }} />
            <View style={styles.detailView}>
                <View style={[styles.detailContent, !isMobileDevice && { width: "40%", minWidth: 400 }]}>
                    {sectionIndex >= CarInsuranceKeys.length ? renderCompleteView()
                        : renderQuoteDetailView(CarInsuranceInfo[CarInsuranceKeys[sectionIndex]][blockIndex])}
                </View>
            </View >

            <Footer textStyle={{ color: "black" }}
                style={[{ position: "absolute", marginTop: window.innerHeight * 0.94, flexDirection: "row", justifyContent: "center", width: "100%", alignSelf: "flex-end" }]}>
            </Footer>
        </>
    );
}

const styles = StyleSheet.create({
    title: {
        color: "#111111",
        fontSize: 20,
        lineHeight: "100%",
        fontWeight: "600",
        marginVertical: 10
    },
    description: {
        color: "#111111",
        fontSize: 14,
        lineHeight: "100%",
        fontWeight: "600",
        marginVertical: 10,
        opacity: 0.75
    },
    button: {
        borderRadius: 60,
        backgroundColor: "rgba(112, 225, 137, 0.45)",
        flex: 1,
        marginHorizontal: 5,
        alignItems: "center",
        justifyContent: "center",
        marginTop: 20,
        marginBottom: 15,
        height: 40,
        width: "100%"
    },
    buttonActive: {
        backgroundColor: "#70E189"
    },
    buttonContent: {
        color: "#191C1F",
        fontSize: 14,
        lineHeight: "100%",
        fontWeight: "700",
        height: 30,
        marginTop: 12
    },
    optionButton: {
        marginVertical: 5,
        height: 40,
        borderRadius: 7,
        borderColor: "rgba(11, 60, 73, 0.35)",
        borderWidth: 1,
        backgroundColor: "#F0FAF5",
        flex: 1,
        marginHorizontal: 5,
        alignItems: "center",
        width: "100%"
    },
    optionButtonActive: {
        borderWidth: 2,
        borderColor: "#70E189",
        backgroundColor: "#B8F0C4"
    },
    optionButtonContent: {
        color: "#000000",
        fontSize: 14,
        lineHeight: "100%",
        fontWeight: "600",
        marginVertical: 15,
        opacity: 0.75
    },
    textInputView: {
        marginVertical: 5,
        width: "100%",
        height: 40,
        flexDirection: "row"
    },
    textInput: {
        borderRadius: 7,
        borderColor: "rgba(11, 60, 73, 0.35)",
        borderWidth: 1,
        width: "100%",
        paddingHorizontal: 15
    },
    columnDirectionView: {
        flexDirection: "column",
        width: "100%",
        alignItems: "center"
    },
    completeImage: {
        width: "80%",
        height: 200
    },
    detailView: {
        width: "100%",
        height: window.innerHeight * 0.9,
        backgroundColor: "#EAF8F0",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "relative"
    },
    detailContent: {
        width: "90%",
        backgroundColor: "white",
        marginTop: 20, borderRadius: 10,
        borderColor: "rgba(11, 60, 73, 0.50)",
        borderWidth: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: 15
    }
})

const messages = {
    complete: "Complete!",
    completeDescription: "One of our insurance partners will be in contact with you shortly with more information."
}

export default AutoInsuranceTabs;
