import * as React from "react"
import Svg, {Path} from "react-native-svg"

function AboutIconNormal(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
        >
            <Path
                opacity={0.5}
                d="M24 8.281v2.625a.75.75 0 11-1.5 0V8.281h-2.625a.75.75 0 110-1.5H22.5a1.5 1.5 0 011.5 1.5zm-.75 11.625a.75.75 0 00-.75.75v2.625h-2.625a.75.75 0 000 1.5H22.5a1.5 1.5 0 001.5-1.5v-2.625a.75.75 0 00-.75-.75zm-13.125 3.375H7.5v-2.625a.75.75 0 00-1.5 0v2.625a1.5 1.5 0 001.5 1.5h2.625a.75.75 0 100-1.5zM6.75 11.656a.75.75 0 00.75-.75V8.281h2.625a.75.75 0 100-1.5H7.5a1.5 1.5 0 00-1.5 1.5v2.625a.75.75 0 00.75.75zm5.719 5.392A6.37 6.37 0 009.9 19.08a.75.75 0 00.6 1.201h9a.75.75 0 00.6-1.2 6.37 6.37 0 00-2.569-2.033 3.75 3.75 0 10-5.057 0h-.005z"
                fill="#000"
            />
        </Svg>
    )
}

export default AboutIconNormal;
