import { View, Text } from 'react-native';
const TabItem = (props) => {
    const borderColor = props.isActive ? "#0B3C49" : "#D9D9D9";

    return (
        <View style={{justifyContent: 'center', alignItems: 'center'}}>
            <View style={{ width: 30, height: 30, justifyContent: "center", alignItems: "center" }}>
                {!props.isComplete && <View style={{ width: "100%", height: "100%", borderRadius: 15, borderColor: borderColor, position: "absolute", borderWidth: 2 }}></View>}
                {props.children}
            </View>
            <Text style={[{ color: "#0B3C49", fontSize: 12, fontWeight: "700", marginTop: 10 }, props.isActive && { color: "#111111", opacity: 0.75 }]}>
                {props.title}
            </Text>
        </View>);
}

export default TabItem;
