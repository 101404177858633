import * as React from "react"
import Svg, { Path } from "react-native-svg"

function QuoteIconNormal(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={29}
            height={29}
            viewBox="0 0 29 29"
            fill="none"
            {...props}
        >
            <Path
                opacity={0.5}
                d="M17.395 12.342v-.305c0-1.373-1.952-2.408-4.541-2.408-2.59 0-4.541 1.036-4.541 2.408v2.19c0 1.143 1.354 2.052 3.302 2.324v.303c0 1.373 1.952 2.408 4.541 2.408 2.59 0 4.542-1.035 4.542-2.408v-2.19c0-1.133-1.312-2.042-3.303-2.322zm-6.605 3.136c-1.011-.299-1.652-.792-1.652-1.251v-.77c.421.316.985.57 1.652.742v1.28zm4.128-1.279c.666-.171 1.23-.426 1.651-.742v.77c0 .459-.64.952-1.651 1.251V14.2zm-.826 3.907c-1.01-.3-1.651-.793-1.651-1.252v-.228a9.399 9.399 0 001-.01c.214.08.43.15.651.206v1.284zm0-2.443c-.41.065-.824.097-1.238.096a7.93 7.93 0 01-1.239-.096v-1.302c.41.057.825.085 1.239.085a8.96 8.96 0 001.238-.085v1.302zm3.303 2.628a8.056 8.056 0 01-2.477 0v-1.306a8.927 8.927 0 002.477.003v1.303zm2.477-1.437c0 .46-.64.952-1.651 1.252v-1.28c.666-.17 1.23-.426 1.65-.742v.77z"
                fill="#000"
            />
        </Svg>
    )
}

export default QuoteIconNormal;
